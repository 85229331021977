import { Injectable } from '@angular/core';
import { urls, payloads } from '../../common/model/properties';
import { FieldValidateService } from '../../common_services/fieldvalidate.service';

import { ConfigProvider } from '../../shared/services/configprovider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../../secure-storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class AccountProfileService {

    SKIP_NPI_VALIDATION = false;
    SKIP_TAXID_VALIDATION = false;


    private forgotUser: any = '';
    encodedAddress1: any;
    encodedAddress2: any;
    props:any;
    constructor(private http: HttpClient, private fieldValidator: FieldValidateService,
        private configProvider:ConfigProvider,
        private secureStorage:SecureStorageService) { 
            this.props = configProvider.getProps();
        }

    getUserProfile(userName, callBack: (sucess: boolean, message: string) => void) {

        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        // const headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + ppjwt.access_token
        // });
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

        this.http.get(urls.accountProfileUrl, { headers: headers })
            .subscribe(
                (resp) => {
                    callBack(true, resp['user'][0]);
                },
                err => {
                    callBack(false, 'Error while getting the profile : ');
                }
            );
    }
    getUserProfile2( userName): Promise<any> {
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Accept':'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        return this.http.get( urls.accountProfileUrl, {headers: headers}).toPromise();
    }
    updateSecurityQuestion(
        userName,
        question1,
        question2,
        answer1,
        answer2,
        cred,
        authToken,
        callBack: (sucess: boolean, message: string) => void) {
        //this.removeCookies();
        //sessionStorage.clearAll();
        let id = this.secureStorage.getSecureInfoInSessionStorage('JSESSIONID');
        sessionStorage.removeItem('id');
        const headers = new HttpHeaders({
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'SOAPAction': 'urn:setUserChallengeAnswers',
            'withCredentials': 'true'
        });
        // headers.set('Accept', '*/*');
        // headers.set('Content-Type', 'application/json');
        //headers.set('Content-Type', 'text/xml;charset=UTF-8');
        // headers.set('Authorization', `Basic ${authToken}`);
        // headers.set('SOAPAction', 'urn:setUserChallengeAnswers');
        //headers.set('withCredentials', 'false');
        // headers.set('Access-Control-Allow-Credentials','false');
        // headers.set('Cache-Control','no-cache');
        // headers.set('Pragma','no-cache');
        // headers.set('Expires','0'); 
        // headers.set('withCredentials', 'true');

        // let question1Payload = '';
        // let question2Payload = '';

        // if (question1 !== '' && answer1 !== '') {
        //     question1Payload = payloads.question1Payload;

        //     question1Payload = question1Payload.replace('QUESTION_ID', '1');
        //     question1Payload = question1Payload.replace('USER_QUESTION', question1);
        //     question1Payload = question1Payload.replace('USER_ANSWER', answer1);
        // }

        // if (question2 !== '' && answer2 !== '') {
        //     question2Payload = payloads.question2Payload;

        //     question2Payload = question2Payload.replace('QUESTION_ID', '2');
        //     question2Payload = question2Payload.replace('USER_QUESTION', question2);
        //     question2Payload = question2Payload.replace('USER_ANSWER', answer2);
        // }

        // let fullQuestionPayload = payloads.setSecQuestionsNewPayload.replace('USER_NAME', userName);
        // fullQuestionPayload = fullQuestionPayload.replace('QUESTION1_PAYLOAD', question1Payload);
        // fullQuestionPayload = fullQuestionPayload.replace('QUESTION2_PAYLOAD', question2Payload);
        const fullQuestionPayload = {
            userName: userName,
            secret: cred,
            question1: question1,
            answer1: answer1,
            question2: question2,
            answer2: answer2
        };
        this.http.post(urls.postSecurityQuestion, fullQuestionPayload, { headers: headers,responseType:'text', withCredentials: true })
            .subscribe(
                (resp) => {
                    callBack(true, resp);
                },
                err => {
                    callBack(false, 'Error while persisting user challenges for user : ' + userName);
                }
            );
    }
    updateData(registerDataPayload, callBack: (sucess: boolean, message: string) => void) {

        
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        //const registerUserPayload = this.constructRegisterUserEmailPayload();
        // this.updateUserEmailId((registerDataPayload.userName), registerDataPayload.email, (success: boolean, message: string) => {
        //     if (success) {
                this.http.put(urls.updateDataUrl, registerDataPayload, { headers: headers,responseType:'text' })
                    .subscribe(
                        (resp:any) => {
                            callBack(true, resp);
                        },
                        err => {
                            callBack(false, err);
                        }
                    );
          //  }
       // });
    }


    parseUspsAdress(uspsResponse) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(uspsResponse, 'text/xml');
        try {
            const addressModel = {};
            const addressElement = xmlDoc.getElementsByTagName('Address')[0];
            if (!addressElement.getElementsByTagName('Error')[0]) {
                addressModel['address1'] = this.extractValueFromNode(addressElement, 'Address2');
                addressModel['address2'] = this.extractValueFromNode(addressElement, 'Address1');
                addressModel['city'] = this.extractValueFromNode(addressElement, 'City');
                addressModel['state'] = this.extractValueFromNode(addressElement, 'State');
                addressModel['zip'] = this.extractValueFromNode(addressElement, 'Zip5');
                return addressModel;
            }
        } catch (e) { }
    }

    extractValueFromNode(xmlDoc, key) {
        let value = '';
        try {
            value = xmlDoc.getElementsByTagName(key)[0].textContent;
        } catch (e) { }
        return value;
    }

    doUspsValidation(model, callBack: (sucess: boolean, message) => void) {

         // usps address validation for #
        //  let payLoad = payloads.uspsPayLoad;

        //  if (model.address1.indexOf('#') != -1 || model.address2.indexOf('#') != -1) {
        //      this.encodedAddress1 = model.address1.replace('#', '');
        //      this.encodedAddress2 = model.address2.replace('#', '');
        //      payLoad = payLoad.replace('#ADDRESS2#', this.encodedAddress1);
        //      payLoad = payLoad.replace('#ADDRESS1#', this.encodedAddress2);
        //  }else{
        //      payLoad = payLoad.replace('#ADDRESS2#', model.address1);
        //      payLoad = payLoad.replace('#ADDRESS1#', model.address2);
        //  }
        // payLoad = payLoad.replace('#CITY#', model.city);
        // payLoad = payLoad.replace('#STATE#', model.state);
        // payLoad = payLoad.replace('#ZIP#', model.zip);

        let payload = {
            "address1" :  model.address1.replace('#', ''),
            "address2" : model.address2.replace('#', ''),
            "city" : model.city,
            "state" : model.state,
            "zip5" : model.zip
            }

        // const headers = new Headers();
        // headers.set('Content-Type', 'text/xml;charset=UTF-8');
        // headers.set('Accept', 'application/xml');
        const headers = new HttpHeaders({
            
        });
        this.http.post(urls.uspsValidateUrl , payload,{headers:headers,responseType:'text'})
            .subscribe(
                (response) => {
                    const addressModel = this.parseUspsAdress(response);
                    if (addressModel) {
                        callBack(true, addressModel);
                    } else {
                        callBack(false, addressModel);
                    }
                },
                err => {
                    callBack(false, err);
                }
            );
    }

    validateNPI(npivalue, callBack: (success: boolean, message: string) => void) {

        if (npivalue === '') {
            callBack(true, '');
            return;
        }
        if (this.fieldValidator.validateNumeric(npivalue.toString(), 10)) {
            if (this.SKIP_NPI_VALIDATION) {
                callBack(true, '');
                return;
            }

            const headers = new HttpHeaders({              
            });

            this.http.get(urls.altIdNews + npivalue, { headers: headers }).subscribe((data:any) => {
                if (data.validityFlag === 1) {
                    callBack(true, '');
                } else {
                    callBack(false, 'Invalid NPI');

                }
            }, (err) => {
                callBack(false, err);
            });

        } else {
            callBack(false, 'The length of NPI should be 10 digits');
        }
    }

    validateTaxId(taxId, callBack: (sucess: boolean, message: string) => void) {

        if (taxId === '') {
            callBack(true, '');
            return;
        }
        if (this.fieldValidator.validateNumeric(taxId, 9)) {
            if (this.SKIP_TAXID_VALIDATION) {
                callBack(true, '');
                return;
            }

            
            const headers = new HttpHeaders({
                
            });

            this.http.get(urls.altIdNews + taxId, { headers: headers }).subscribe((data:any) => {

                if (data.validityFlag === 1) {
                    callBack(true, '');
                } else {
                    callBack(false, 'Invalid Tax ID');
                }
            }, (err) => {
                callBack(false, err);
            });
        } else {
            callBack(false, 'The length of TaxId should be 9 digits');
        }
    }

   
    getSecurityQuestions(): Observable<any> {
        // const headers = new Headers();
        //const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        // headers.set('Content-Type', 'application/json');
        // headers.set('Authorization', 'Bearer ' + ppjwt.access_token);
        // const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
           
        });
        return this.http.get(urls.securityQuestions, { headers: headers });
    }

    removeDocAttributes() {
        const cookies = document.cookie;

        for (let i = 0; i < cookies.split(';').length; ++i) {
            const myCookie = cookies[i];
            const pos = myCookie.indexOf('=');
            const name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    // updateUserEmailId(userName, emailId, callBack: (sucess: boolean, message: string) => void) {
    //     let payload = payloads.registerEmailPayload.replace('USER_NAME', userName);
    //     payload = payload.replace('EMAIL_ID', emailId);

    //     // let headers = new Headers();
    //     // let ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    //     // headers.set( 'Authorization', 'Bearer ' +  ppjwt.access_token );
    //     const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
    //     const headers = new HttpHeaders({
    //         'Accept': 'application/json',
    //         'Authorization': 'Bearer ' + token
    //     });
    //     // const headers = new Headers();
    //     // headers.set( 'Authorization', this.props.wso_authorization );
      
    //     this.http.post( urls.updateEmailId, payload, { headers: headers ,responseType:'text'} )
    //         .subscribe(
    //             (resp) => {
    //                 callBack(true, '');
    //             },
    //             err => {
    //                 callBack(false, 'Error while changing the EmailID : ');
    //             }
    //         );
    // }

    getStatesList() {
        // const headers = new Headers();
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        // headers.set('Content-Type', 'application/json');
        // headers.set('Authorization', 'Bearer ' + ppjwt.access_token);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        // const userObj = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const userObj = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        let path = urls.getStates;
        return this.http.get(path, { headers: headers })
    }


}
