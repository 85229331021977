export let urlProps = {

  changePasswordUrl: '/providerauth/profile/providerdetails/updateUserPassword',
  updateLockedPasswordUrl: 'providerauth/profile/providerdetails/changeUserPassword',
  filenetURL: '/providerauth/filenet/1.0/document',
  filenetListURL: '/providerauth/filenet/1.0/listDocuments',
  filenetFolderURL: '/providerauth/filenet/1.0/listFolderItems',
  carePlanDocDownload: '/providerauth/providerportal/v1.1/getEdsListDocuments',
  tokenUrl: '/providerauth/login/oauth/token',
  jwtLogin: '/cupauth/auth/oauth/token',
  tokenRefreshUrl: '/providerauth/public/api/getUserRefreshToken',
  jwtRefreshLogin: '/providerauth/public/api/getCupUserRefreshToken',
  sendEmailUrl: '/providerauth/email/CaremoreEmailService.CaremoreEmailServiceHttpSoap11Endpoint',
  sendEmail: '/providerauth/profile/providerdetails/notificationEmailWithSubject',
  activationMail: '/providerauth/profile/providerdetails/activationNotificationMail',
  deactivationMail: '/providerauth/profile/providerdetails/deactivationNotificationMail',
  enquiryList: '/providerauth/profile/providerdetails/getEnquiry',
  updateDataUrl: '/providerauth/profile/providerdetails/updateProviderPortalURData',
  unlockUserUrl: '/providerauth/profile/providerdetails/unlockUserAccount',
  //updateLockedUerStatusUrl: '/providerauth/reg/updateProviderPortalURData/',
  addStaffDataUrl: '/providerauth/profile/providerdetails/postNotificationandAssociateURData',
  addAdminStaffDataUrl: '/providerauth/providerportal/v1.1/postAdminNotificationandAssociateURData',
  //accountProfileUrl: '/providerauth/reg/getProviderPortalURData?userName=USER_NAME',
  //authUserList: '/providerauth/profile/providerdetails/getProviderPortalURData?guId=GU_ID&status=STATUS',
  authUserList: '/providerauth/profile/providerdetails/getAuthUserList?status=STATUS',
  //getUnregisterdUsers: '/providerauth/profile/providerdetails/getUnregisterdUsers?guId=GU_ID',
  getUnregisterdUsers: '/providerauth/profile/providerdetails/getUnregAuthdUsers', 
  getClaimSummary: '/providerauth/profile/providerdetails/providerclaimsummary/',
  getClaimdetail: '/providerauth/profile/providerdetails/providerclaimdetail/',
  generatePasscode: '/providerauth/profile/providerdetails/emailWithPasscodeForCapReports/',
  getProviderEmail: '/providerauth/profile/providerdetails/GetProviderDetailsFromEDH?providerId=pID',
  capReportsURL: '/providerauth/filenet/1.0/listDocuments?appId=PC&docCode=CAP_REPORTS&keyValuePairList=%5B%7B%22key%22:%22ProviderId%22,%22value%22:%22PrID%22%7D%5D',
  validatePasscode: '/providerauth/profile/providerdetails/passcodeValidation?providerID=pID&passcode=pCode%20',
  getCapReportDoc: '/providerauth/filenet/1.0/document/docId?appId=PC&docCode=CAP_REPORT',
  updateStatus: '/providerauth/profile/providerdetails/updateProviderPortalURData',
  updateAssociateURData: '/providerauth/profile/providerdetails/resendNotificationemail',
  updateAssociateURDataPut:'/providerauth/profile/providerdetails/updateAssociateURData',
  validateConfirmUrl: '/providerauth/confirmation/identity/user/v1.0/validate-code',
  resendConfirmUrl: '/providerauth/resend/identity/user/v1.0/resend-code',
  setSecureQuestionsUrl: '/providerauth/setquestions/ChallengeQuestionManagementAdminService.ChallengeQuestionManagementAdminServiceHttpsSoap11Endpoint/',
  getQuesionsChallenge:'/providerauth/setquestions/ChallengeQuestionManagementAdminService.ChallengeQuestionManagementAdminServiceHttpsSoap11Endpoint/',
  getSecurityQuestionsUrlApi: '/providerauth/profile/providerdetails/getSecurityQuestions',
  //getRegistertationTypes: '/providerauth/reg/getAllUserType',
  //registerUrl: '/providerauth/is/v1.2/user/v1.0/me',
  registerUrl: '/providerauth/public/api/saveUserinWso2Me',
  uspsValidateUrl: '/providerauth/public/api/UspsAddressValidation',
  getRegInfo: '/providerauth/public/api/getUserRegInfo',
  //registerDataUrl: '/providerauth/reg/postProviderPortalURData/',
  //validateUserName: '/providerauth/reg/userNameExistenceValidation/',
  //getAssociatedData: '/providerauth/reg/getAssociateDataWhenValidEmail?associateId=',
  //emailExistence: '/providerauth/reg/emailExistenceValidation',
  //recoverUserNameUrl: '/providerauth/reg/postForgotUserName',
  recoverPasswordUrl: '/providerauth/public/api/sendPasswordRecoveryMail',
  resetPasswordUrl: '/providerauth/public/api/resetPassword',
  getSecurityQuestionsUrl: '/providerauth/public/api/getUserSecurityQuestions',
  validateSecurityQuestionsUrl: '/providerauth/public/api/validateSecurityQuestionAnswers',
  //altIdNews: '/providerauth/reg/getEdhAltId/',
  //getNews: '/providerauth/reg/getNews?type=news&count=1',
  ldapUserCheck: '/providerauth/faq/getLdapUserData?userName=USER_NAME',
  addDND: '/providerauth/broadcastfaq/1.0/addDoNotDisturbDetails',
  getDND: '/providerauth/broadcastfaq/1.0/getDoNotDisturbdata?userId=USER_NAME&messageNo=MESSAGE_NO',
  getBroadcast: '/providerauth/broadcastfaq/1.0/getBroadcastDetails?type=broadcast&count=1',
  getWarningMsg: '/providerauth/broadcastfaq/1.0/getBroadcastDetails?type=Warnmsg&count=1',
  getFaqs: '/providerauth/broadcastfaq/1.0/getFaqDetails',
  getBroadcastHistory: '/providerauth/broadcastfaq/1.0/getBroadcastDetails?type=broadcast&count=15',
  checkProviderAdmin: '/providerauth/profile/providerdetails/search',
  updateProviderAdmin: '/providerauth/profile/providerdetails/updateProviderAdminAssociateRelationURData',
  getProviderAddress : '/providerauth/profile/providerdetails/getprovideraddressfrompods',
  uspsCityStateLookupUrl: '/providerauth/usps/ShippingAPITest.dll?API=CityStateLookup&xml=',
  // listSites: '/providerauth/site/api/v1.0/sites?userid=USER_NAME',
  // addSites: '/providerauth/site/api/v1.0/usersite',
  // deleteSites: '/providerauth/site/api/v1.0/deletesite?userid=USER_NAME',
  getManagedUserSites:'/providerauth/providerportal/v1.1/getManagedUserSites',
  addCupSite:'/providerauth/providerportal/v1.1/addCupSite',
  deleteManagedUserSites:'/providerauth/providerportal/v1.1/deleteCupSite',
  //getUserAgreementDoc: '/providerauth/reg/getDocument',
  //autoPopulateUserName: '/providerauth/reg/getUserName?firstName=FIRST_NAME&lastName=LAST_NAME',
  //securityQuestions: '/providerauth/reg/getSecurityQuestions',
  //postSecurityQuestion: '/providerauth/reg/postAdminService',
  practseIDUrl:'/providerauth/profile/providerdetails/getpracticeid',
  sendPdfDetails: '/providerauth/profile/providerdetails/getmergedocument',
  //getInternalUserType: '/providerauth/reg/getInternalUserType',
  getAlerts:'/providerauth/profile/providerdetails/getProviderPortalAlerts',
  getAlertReadStatus : '/providerauth/profile/providerdetails/postProviderPortalAlertsReadReceipt',
  getAlertCount: '/providerauth/profile/providerdetails/getProviderPortalAlertsCount',
  getAlertCountSev: '/providerauth/profile/providerdetails/getProviderPortalAlertsSeverityCount',
  //getEpahafStatus: '/providerauth/profile/providerdetails/getmemberdetailsfrompahaf',
  //getEpahafStatus: '/providerauth/profile/providerdetails/getIndividualpahafmemberdetails', 
  getEpahafStatus: '/providerauth/profile/providerdetails/getIndividualpahafmemberdetailsPost', 
  getMemberMeasuresPahaf:'/providerauth/profile/providerdetails/getpahafmembermeasures',
  geteligibiREportDetails :'/providerauth/profile/providerdetails/getpcpmemberinfoDetails',
  getPotenitalData:'/providerauth/profile/providerdetails/getPotentialDatafromPahaf',
  getpahafconfiginfo:'/providerauth/profile/providerdetails/getpahafconfiginfo',
  // mock data URLs
  //validateEmailExistenceInSBI: '/providerauth/reg/emailexistencevalidation/',

  // Messaging URLS Starts //
  getreceivedMessages: '/providerauth/providerportalmsg/api/v1.0/receivedmessage',
  getSentMessages: '/providerauth/providerportalmsg/api/v1.0/sentmessage',
  replyMessage: '/providerauth/providerportalmsg/api/v1.0/{messageUid}/',
  msgDetail: '/providerauth/providerportalmsg/api/v1.0/',
  deleteMessages: '/providerauth/providerportalmsg/api/v1.0/deletemessage/',
  recipientTypes: '/providerauth/providerportalmsg/api/v1.0/recipienttype/',
  messageApplications: '/providerauth/providerportalmsg/api/v1.0/applications',
  getClickHereForDetailURL: '/providerauth/profile/providerdetails/getmessageentitylink?entityType=',
  sendMessage: '/api/v1.0/sendmessage/',
  sendMessageProvider:'/providerauth/providerportal/v1.1/sendMessageProvider',
  sendMessageCUP:'/providerauth/providerportal/v1.1/sendMessageCUP',
  recipientgroup : '/api/v1.0/recipientgroup/',
  recipient : '/api/v1.0/recipient/',
  fileNetUrl: '/providerauth/eds/1.0/document/',
  uploadEdsDoc:'/providerauth/providerportal/v1.1/uploadEdsDoc',
  fileNetListUrl: '/providerauth/eds/1.0/listDocuments',
  getTrashMessages: '/providerauth/providerportalmsg/api/v1.0/trashmessage',

  unreadMessageConut: '/providerauth/providerportalmsg/api/v1.0/unread',
  updateMsgStatusToRead: '/providerauth/providerportalmsg/api/v1.0/readmessage',
  userPreferenceSaveURL: '/providerauth/providerportalmsg/api/v1.0/preferencesetting',
  userPreferencesSettingUrl: '/providerauth/providerportalmsg/api/v1.0/preferencesetting/',

  // Messaging URLS Ends //

  getQM: 'cupauth/edh4/v1.1/qmMemberListView/',
  claimUrl:'cupauth/edh/v1.4/claimSummary/',
  providerAffiliation: '/cupauth/cup/api/v1.0/provider/affiliation/',
  //measureStarRating: '/providerauth/reg/getStarRating?measureId={measureId}&targetPercent={targetPercent}',

  // Manage Users //
  getSiteUsers: '/providerauth/providerportal/v1.1/getCUPSitesActiveUsers',


  lookupZiporCity:'/providerauth/cupapi/api/v1.0/lookup/zipcodecity?code=',


  getClientDetails:'/providerauth/providerportal/v1.1/getCUPSites',
  getStates: '/providerauth/cupapi/api/v1.0/lookup/state',
  majorStatusChange: '/providerauth/reg/getMajorStatusChange',
  //memberMedicationData:'/providerauth/reg/getMemberMedicationData',
  //getmemberData:'/providerauth/reg/getMemberData',
  //getMemberPCP:'/providerauth/reg/getpcpmemberdetails',
  //memberInpatientData:'/providerauth/reg/getHospitalAdmissionDetails',
  // getClinicalSummary: '/providerauth/nextgen/',
  // getReasonForVisit: '/providerauth/patient/',
  //getClinicalSummary: '/providerauth/nextgen/clinical/1.0/summary',
  getClinicalSummary: '/providerauth/providerportal/v1.1/summary',
  //getReasonForVisit: '/providerauth/patient/v1.0/reasonforvisit',  
  getReasonForVisit: '/providerauth/providerportal/v1.1/reasonForVisit',
  
  //getclinicalSummaryFlag: '/providerauth/reg/getClinicalSummaryFlag',
  //getPersonId : '/providerauth/reg/getPersonId',
  appointmentDetails:'/providerauth/NexGen/v1.1/getPtApptData/personID/P/starTDate/enDDate',
  //appointmentDetails:'/providerauth/NexGen/v1.1/getPtApptData',
  //appointmentDetails:'https://apimtst.caremore.com/NexGen/v1.1/getPtApptData/C055CED6-4336-4499-8C12-967ADB74DD4D/P/20200826/99991231',
  //Onboarding Documents Urls
  //requestProfile: 'https://ca47twvwbs002.caremore.com:9443/PCSRestServices/getFolder?',
  //requestProfile: '/providerauth/reg/getFolder?',
  //requestDocumentTypes: 'https://ca47twvwbs002.caremore.com:9443/PCSRestServices/getDocument?',
  //requestDocumentTypes: '/providerauth/reg/getDocumentType?',
  //retrieveDocument:'https://ca47twvwbs002.caremore.com:9443/EDSWebServicesREST/listDocuments?',
  //documentList: '/eds/1.0/listDocuments',
  documentList: '/providerauth/uploadeds/1.0/listDocuments',
  //uploadDocument:'https://ca47twvwbs002.caremore.com:9443/EDSWebServicesREST/document/',
  uploadDocument:'/providerauth/uploadeds/1.0/document/',
  //loginUploadTokenURL: '/token',
  loginUploadTokenURL: '/providerauth/uploadtoken/',
  apimUrl:'/token',
  //getUploadedDocURL: 'https://ca47twvwbs002.caremore.com:9443/EDSWebServicesREST/document/docID?appId=PCS&documentCode=PROVIDER_DOC',
  getUploadedDocURL: '/providerauth/uploadeds/1.0/document/docID?appId=PCS&documentCode=PROVIDER_DOC',
  getHraDocURL:'http://ca47twvwbs002:9080/EDSWebServicesREST/listDocuments?',
  //getRedirectionURL:'https://cddtstwebv001.caremore.com:443/epahaf/',
  getRedirectionURL:'/epahaf/epahaf?hmodID=pID&appName=pCode&userName=user_name',
  //getRedirectionURL : 'https://ca47twviss319.caremore.com:21443/epahaf/',
  //getHraDocURL:'https://apimtst.caremore.com/eds/1.0/listDocuments?',
  deleteuploadedDocURL:'/providerauth/uploadeds/1.0/document/docID?appId=PCS&documentCode=PROVIDER_DOC&deletedByUser=PROVIDER_NAME',
  getAddress: '/providerauth/pods/1.0/api/Outreach/ProviderProfile/GetProviderDetails',
  ssoUsername: 'providerauth/cupapi/api/v1.0/user/npitaxid?email={EMAIL}&npiid={NPIID}&taxid={TAXID}',
  //postSSOClientUsername: 'providerauth/reg/postSSOClientUsername',

       //Added as part of PAHAF Dashboard    
  //getNewMemberPCP : '/providerauth/reg/getpahafmemberdetails',

  //getNewMemberPCPChart : 'https://ca47pwviss032.caremore.com:9006/providerdetails/getpahafmemberdetailswithoutmeasurecount',
  //getNewMemberPCPChart : '/providerauth/reg/getpahafmemberdetailswithoutmeasurecount',

  //getMemberMeasureDetails : '/providerauth/reg/getMemberMeasureDetails/',
  getAccessToken: '/providerauth/token',
  getNews: 'providerauth/public/api/getNews?type=news&count=1',
  updateLockedUerStatusUrl: '/providerauth/providerportal/v1.1/updateProviderPortalURData/',
  accountProfileUrl: '/providerauth/providerportal/v1.1/getProviderPortalURDataAuth',
  accountProfileUrlforManageUser: '/providerauth/providerportal/v1.1/getProviderPortalURDataManageUser',  
  getRegistertationTypes: '/providerauth/providerportal/v1.1/getAllUserType',
  registerDataUrl: '/providerauth/public/api/postProviderPortalURData/',
  validateUserName: '/providerauth/public/api/userNameExistenceValidation/',
  getAssociatedData: '/providerauth/public/api/getAssociateDataWhenValidEmail?associateId=',
  emailExistence: '/providerauth/public/api/emailExistenceValidation',
  recoverUserNameUrl: '/providerauth/public/api/postForgotUserName',
  altIdNews: '/providerauth/public/api/getEdhAltId/',
  getUserAgreementDoc: '/providerauth/public/api/getDocument',
  // autoPopulateUserName: '/providerauth/public/api/getUserName?firstName=FIRST_NAME&lastName=LAST_NAME',
  autoPopulateUserName: '/providerauth/public/api/getUserName',
  securityQuestions: '/providerauth/public/api/getUserSecurityQuestions',
  registerSecurityQuestions: '/providerauth/public/api/getSecurityQuestions',
  postSecurityQuestion: '/providerauth/public/api/postAdminService',
  getInternalUserType: '/providerauth/public/api/getInternalUserType',
  measureStarRating: '/providerauth/providerportal/v1.1/v1.1/getStarRating?measureId={measureId}&targetPercent={targetPercent}',
  //memberMedicationData:'/providerauth/providerportal/v1.1/getMemberMedicationData',
  memberMedicationData:'/providerauth/providerportal/v1.1/getMemberMedicationDataPost',
  getmemberData:'/providerauth/providerportal/v1.1/getMemberData',
  getMemberPCP:'/providerauth/providerportal/v1.1/getpcpmemberdetails',
  //memberInpatientData:'/providerauth/providerportal/v1.1/getHospitalAdmissionDetails',
  memberInpatientData:'/providerauth/providerportal/v1.1/getHospitalAdmissionDetailsPost',
  getclinicalSummaryFlag: '/providerauth/providerportal/v1.1/getClinicalSummaryFlag',
  getPersonId : '/providerauth/providerportal/v1.1/fetchPersonId',
  requestProfile: '/providerauth/providerportal/v1.1/getFolder?',
  requestDocumentTypes: '/providerauth/providerportal/v1.1/getDocumentType?',
  getNewMemberPCP : '/providerauth/providerportal/v1.1/getpahafmemberdetails',
  getNewMemberPCPChart : '/providerauth/providerportal/v1.1/getpahafmemberdetailswithoutmeasurecount',
  //getNewMemberPCPChart : '/providerauth/providerportal/v1.1/getPCPMemberDetailsNew?',  
  getMemberMeasureDetails : '/providerauth/providerportal/v1.1/getMemberMeasureDetails',
  postSSOClientUsername: '/providerauth/providerportal/v1.1/postSSOClientUsername',
  getPCPScoreCard : '/providerauth/reg/getPCPScoreCardDetails',
  getProviderdIdsAttested: '/providerauth/profile/providerdetails/GetProviderIdsAttested',
  submitTrainingAttestation: '/providerauth/profile/providerdetails/saveProviderTrainingAttestation',
  deleteUploadDocument: '/providerauth/providerportal/v1.1/deletePahafDoc',
  saveDocuments: '/providerauth/providerportal/v1.1/savePahafDocumentDetails',
  getProviderAffiliationsWRTsite : '/providerauth/profile/providerdetails/getProviderAffiliationsWRTsite/',
  getDropDownList : '/providerauth/profile/providerdetails/getPatientExpDropDownsList?',
  postPatientDetails : '/providerauth/profile/providerdetails/postPatientExpSurvey',
  getPatientDetails : '/providerauth/profile/providerdetails/getPatientSurveyReportHistory',
  getMeasureDetails : '/providerauth/providerportal/v1.1/getMeasureDetailsfromedh',
  getProviderDetailsPost: '/providerauth/profile/providerdetails/getProviderDetails',
  loginNew:'/providerauth/public/api/login',
  qmMemberSearch:'/providerauth/providerportal/v1.1/memberSearch',
  qmgetMemeberDetails:'/providerauth/providerportal/v1.1/getMemeberDetails',
  qmgetQualityMeasureDetails:'/providerauth/providerportal/v1.1/getQualityMeasureDetails',
  deletePEUploadDocument: '/providerauth/providerportal/v1.1/deletePEDoc',
  getPrefference: '/providerauth/profile/providerdetails/getPreferencesInfo',
  getDecryptedUserRegInfo: '/providerauth/public/api/getUserRegInfo',
};

