import { Component, OnInit, Input, OnChanges, SimpleChanges, Directive, ElementRef, HostListener } from '@angular/core';
import { AccountProfileService } from './accountProfileEdit.service';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { FieldValidateService } from '../../common_services/fieldvalidate.service';
import { SignupFormService } from '../../signup-form/signup-form.service';
import { Input1Model } from '../../inputComponent/input1.model';

import { Subject } from 'rxjs';
import { Observable } from 'rxjs';


import { ProviderportalService } from '../../providerportal/providerportal.service';
import { ForgotpasswordService } from '../../forgotpassword/forgotpassword.service';
import { SecureStorageService } from '../../secure-storage.service';
import { CupService } from 'src/app/shared/services/cup.service';


declare var jQuery: any;

@Component({
    selector: 'app-accountProfileEdit',
    templateUrl: './accountProfileEdit.component.html',
    styleUrls: ['./accountProfileEdit.component.scss'],
    providers: [AccountProfileService, SignupFormService]
})
export class AccountProfileEditComponent implements OnInit {

    userName: any;
    showContact: any;
    questions: any;
    message: any;
    fieldValidateMsg = {};
    isFiledValid = {};
    isLoaded = false;
    isValidCred = true;
    isValidUserName = true;
    showCred = true;
    UserCred: any = '';
    questionOneSelected: any;
    questionTwoSelected: any;
    uspsAddressModel: any;
    model: any;
    loading = false;
    stateCodes:Array<any> =[];
    // this.loading = false;
    inputComps: Map<string, Input1Model>;

    zipDataSource: Observable<any>;
    cityDataSource: Observable<any>;
    data = [];
    handleResults(searchObj) {
        this.data = searchObj;
    }

    selectedUserAddressCard : boolean = true;

    constructor(private forgotpasswordService: ForgotpasswordService,
        private router: Router, private cupService: CupService,
        private providerportalService: ProviderportalService,
        private editprofileService: AccountProfileService,
        private route: ActivatedRoute,
        private accountProfileService: AccountProfileService,
        private fieldValidator: FieldValidateService,
        private registerService: SignupFormService,
        private secureStorage:SecureStorageService) {
    }

    alertModalEventCallBack() { }

    togglePasswordShow(event: any) {
        this.showCred = !this.showCred;
    }

    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }

    passRestrict(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }

    hideValidate(inputName: string) {
        if (inputName === 'password') {
            this.isValidCred = true;
        }
    }

    UpdateUserSelectedAddress(){

        if(this.selectedUserAddressCard){
            this.uspsModelEventCallBack('SAME_ADDRESS','')
        }
        else{
            this.uspsModelEventCallBack('USPS_ADDRESS', this.uspsAddressModel)
        }
    }

    validateChildInputs() {
        const inputComps = this.inputComps;

        const fieldNameArr = [
            'firstName', 'middleName', 'lastName', 'city', 'state', 'zip', 'phone', 'extn', 'fax', 'email',
            'npi', 'taxId', 'addressLine1', 'addressLine2', 'userName',
            'question1', 'question2', 'answer1', 'answer2'
        ];

        let isValid = true;
        for (const fieldName of fieldNameArr) {
            const inputField = inputComps.get(fieldName);

            if (inputField) {
                const isInputValid = inputField.isValid();
                isValid = isValid && isInputValid;
            }
        }

        if (inputComps.get('npi').value === '' && inputComps.get('taxId').value === '') {

            inputComps.get('npi').fieldValidateMsg = 'NPI or Tax Id is required';
            inputComps.get('taxId').fieldValidateMsg = 'NPI or Tax Id is required';
            inputComps.get('taxId').isInputValid = false;
            inputComps.get('npi').isInputValid = false;
            window.scrollTo(0, 0);
            isValid = false;
        } else {
            if (inputComps.get('taxId').value !== '') {
                if (this.fieldValidator.validateSameNumber(inputComps.get('taxId').value)) {
                    inputComps.get('taxId').fieldValidateMsg = 'Invalid Tax ID';
                    inputComps.get('taxId').isInputValid = false;
                    isValid = false;
                }
            }
            if (inputComps.get('npi').value !== '') {
              if (this.fieldValidator.validateSameNumber(inputComps.get('npi').value)) {
                inputComps.get('npi').fieldValidateMsg = 'Invalid NPI';
                inputComps.get('npi').isInputValid = false;
                isValid = false;
              }
            }
        }

        if (inputComps.get('question1').value !== this.questionOneSelected) {
            if (inputComps.get('question1').value !== '' && inputComps.get('answer1').value === '') {

                inputComps.get('answer1').fieldValidateMsg = 'Provide Answer for Question1';
                inputComps.get('answer1').isInputValid = false;
                isValid = false;
            }
        }

        if (inputComps.get('question2').value !== this.questionTwoSelected) {
            if (inputComps.get('question2').value !== '' && inputComps.get('answer2').value === '') {

                inputComps.get('answer2').fieldValidateMsg = 'Provide Answer for Question2';
                inputComps.get('answer2').isInputValid = false;
                isValid = false;
            }
        }

        if (inputComps.get('question2').value === inputComps.get('question1').value) {
            isValid = false;
            this.message = 'Question1 and Question2 should not be same.Please select different questions.';
            jQuery('#errorModalSameQuestion').modal('show');
        }
        let temp = true;
        if (this.inputComps.get('question1').value !== this.questionOneSelected) {
            temp = false;

        }
        if (this.inputComps.get('question2').value !== this.questionTwoSelected) {
            temp = false;
        }
        if (!temp && (this.inputComps.get('answer1').value === '' || this.inputComps.get('answer2').value == '')) {
            if (this.inputComps.get('answer1').value === '') {
                inputComps.get('answer1').fieldValidateMsg = 'Provide Answer for Question1';
                inputComps.get('answer1').isInputValid = false;
                isValid = false;
            }
            if (this.inputComps.get('answer2').value === '') {
                inputComps.get('answer2').fieldValidateMsg = 'Provide Answer for Question2';
                inputComps.get('answer2').isInputValid = false;
                isValid = false;
            }
        }

        return isValid;
    }

    ngOnInit() {
        this.inputComps = new Map<string, Input1Model>();
        this.zipDataSource = new Observable();
        this.cityDataSource = new Observable();
        // this.userName = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))['userName'];
        this.userName = this.cupService.getUserNameFromJWT();
        this.getUserData();
        this.zipDataSource = Observable.create((observer: any) => {
            this.registerService.lookupZiporCity(this.inputComps.get('zip').value).subscribe(resp => {
                let list = resp;
                observer.next(list);
            })
        });

        this.cityDataSource = Observable.create((observer: any) => {
            if (this.inputComps.get('city').value.length >= 3) {
                this.registerService.lookupZiporCity(this.inputComps.get('city').value).subscribe(resp => {
                    let list = resp;
                    observer.next(list);
                })
            }
        })
        this.getStatesListInfo();
    }
    getUserData() {
        this.loading = true;
        this.accountProfileService.getUserProfile(this.userName, (success: boolean, data: any) => {
            if (success) {
                this.loading = false;
                this.userName = data['userName'];
                this.forgotpasswordService.getSecurityQuestions(this.userName).
                    subscribe(
                        (resp:any) => {
                            this.inputComps.get('question1').value = resp.questions[0].question;
                            this.inputComps.get('question2').value = resp.questions[1].question;
                            this.questionOneSelected = resp.questions[0].question;
                            this.questionTwoSelected = resp.questions[1].question;
                        },
                        err => {
                        }
                    );

                this.constructInputs(data);
            } else {
                this.loading = false;
                this.showAlertPopup(data, () => {
                    this.router.navigate(['providerportal']);
                });
            }
        });
    }

    private constructInputs(data) {
        const inputComps = this.inputComps;

        inputComps.set('firstName', new Input1Model({
            label: 'First Name',
            value: data['firstName'],
            type: Input1Model.TYPE_ALPHA,
            required: true
        }));
        inputComps.set('lastName', new Input1Model({
            label: 'Last Name',
            value: data['lastName'],
            type: Input1Model.TYPE_ALPHA,
            required: true
        }));
        inputComps.set('middleName', new Input1Model({
            label: 'Middle',
            value: data['middleName'],
            type: Input1Model.TYPE_ALPHA
        }));
        inputComps.set('fax', new Input1Model({
            label: 'Fax',
            value: data['fax'] !== 'null' ? data['fax'] : '',
            type: Input1Model.TYPE_PHONE
        }));
        inputComps.set('phone', new Input1Model({ label: 'Phone', value: data['phone'], type: Input1Model.TYPE_PHONE, required: true }));
        inputComps.set('email', new Input1Model({ label: 'Email', value: data['email'], type: Input1Model.TYPE_EMAIL, required: false }));
        inputComps.set('addressLine1', new Input1Model({ label: 'Street Address', value: data['addressLine1'], required: true }));
        inputComps.set('addressLine2', new Input1Model({ label: 'Address Line 2', value: data['addressLine2'], }));
        inputComps.set('answer1', new Input1Model({ label: 'Answer1', value: data['answer1'] }));
        inputComps.set('answer2', new Input1Model({ label: 'Answer2', value: data['answer2'] }));
        inputComps.set('city', new Input1Model({
            label: 'City',
            value: data['city'],
            required: true,
            typeAheadSelect: (e) => {
                this.inputComps.get('zip').value = e.item.zipCode.split('/')[0];
                this.inputComps.get('city').value = e.item.zipCode.split('/')[1];
            },
            isTypeahead: true,
            typeField: 'zipCode'
        }));
        inputComps.set('zip', new Input1Model({
            label: 'Zip',
            value: data['zip'],
            type: Input1Model.TYPE_NUMERIC,
            required: true,
            minLength: 5,
            maxLength: 5,
            typeField: 'zipCode',
            typeAheadSelect: (e) => {
                this.inputComps.get('zip').value = e.value.split('/')[0];
                this.inputComps.get('city').value = e.value.split('/')[1];

            },
            isTypeahead: true,
            dataSource: this.zipDataSource
        }));
        inputComps.set('extn', new Input1Model({
            label: 'Extn',
            value: data['extn'] !== 'null' ? data['extn'] : '',
            type: Input1Model.TYPE_NUMERIC,
            minLength: 5,
            maxLength: 5
        }));
        inputComps.set('npi', new Input1Model({
            label: 'NPI',
            value: data['npi'] !== 'null' ? data['npi'] : '',
            type: Input1Model.TYPE_NUMERIC,
            minLength: 10,
            maxLength: 10
        }));
        inputComps.set('taxId', new Input1Model({
            label: 'Tax Id',
            value: data['taxId'] !== 'null' ? data['taxId'] : '',
            type: Input1Model.TYPE_NUMERIC,
            minLength: 9,
            maxLength: 9
        }));
        inputComps.set('state', new Input1Model({
            label: 'State',
            value: data['state'],
            required: true, isSelect: true,
            options: this.stateCodes
            // ['', 'AZ', 'CA', 'NV', 'VA']
        }));
        inputComps.set('question1', new Input1Model({ label: 'Question1', value: data['question1'], isSelect: true, options: [] }));
        inputComps.set('question2', new Input1Model({ label: 'Question2', value: data['question2'], isSelect: true, options: [] }));
        // this.getQuestions();
        this.getSecurityQuestions();

        this.isLoaded = true;
    }

    validateInputs() {

        const valid = this.validateChildInputs();

        if (valid) {
            const model = {
                address1: this.inputComps.get('addressLine1').value,
                address2: this.inputComps.get('addressLine2').value,
                city: this.inputComps.get('city').value,
                state: this.inputComps.get('state').value,
                zip: this.inputComps.get('zip').value,
            };
            this.loading = true;
            this.accountProfileService.doUspsValidation(model, (hasValidAddress: boolean, uspsAddressModel) => {
                if (hasValidAddress) {
                    this.loading = false;
                    this.uspsAddressModel = uspsAddressModel;
                    this.showPopup('uspsModel', (popupButtonEvent: string, selectedAddressModel) => {
                        if (popupButtonEvent === 'USPS_ADDRESS') {
                            this.inputComps.get('addressLine1').value = selectedAddressModel.address1;
                            this.inputComps.get('addressLine2').value = selectedAddressModel.address2;
                            this.inputComps.get('city').value = selectedAddressModel.city;
                            this.inputComps.get('state').value = selectedAddressModel.state;
                            this.inputComps.get('zip').value = selectedAddressModel.zip;
                        }
                        jQuery('#signInModal').modal({ backdrop: 'static', keyboard: false });
                        jQuery('#signInModal').modal('show');
                    });
                } else {
                    this.loading = false;
                    this.showAlertPopup('The Address is not valid as per \'United States Postal Service\'. Please Verify', undefined);
                }
            });


        }
    }

    updateProfileDetails() {
        let valid = true;
        this.loading = true;
        this.editprofileService.validateNPI(this.inputComps.get('npi').value, (success: boolean, message: string) => {
            if (!success) {
                this.loading = false;
                this.inputComps.get('npi').isInputValid = false;
                this.inputComps.get('npi').fieldValidateMsg = 'NPI is not Valid';
                window.scrollTo(0, 0);
                jQuery('#signInModal').modal('hide');
                valid = false;
            }
            this.editprofileService.validateTaxId(this.inputComps.get('taxId').value, (success: boolean, message: string) => {
                if (!success) {
                    this.loading = false;
                    this.inputComps.get('taxId').isInputValid = false;
                    this.inputComps.get('taxId').fieldValidateMsg = 'Invalid Tax ID';
                    window.scrollTo(0, 0);
                    jQuery('#signInModal').modal('hide');
                    valid = false;
                }
                if (valid) {
                    this.updateProfile();
                    this.loading = false;
                }
            });
        });

    }

    closeModal() {
        jQuery('#signInModal').modal('hide');
    }

    validatePassword() {
        if (this.UserCred === '') {
            if (this.UserCred === '') {
                this.isValidCred = false;
            }
        } else {
            this.loading = true;
            this.providerportalService.providerGetToken(this.userName, this.UserCred)
                .subscribe(
                    (resp) => {
                        this.loading = false;
                        this.updateProfileDetails();
                        jQuery('#signInModal').modal('hide');
                    },
                    err => {
                        this.loading = false;
                        if (err.status === 500) {
                            this.message = 'Server Error, Please click';
                            jQuery('#signInModal').modal('hide');
                            jQuery('#alertModal').modal('show');
                            this.showContact = true;
                        } else {
                            this.message = 'Authentication Failed';
                            jQuery('#signInModal').modal('hide');
                            jQuery('#alertModal').modal('show');
                        }
                    }
                );
        }
    }

    private showAlertPopup(message: string, callBack) {
        this.message = message;
        this.showPopup('alertModal', callBack);
        return;
    }

    private showPopup(popupName: string, callBack) {
        jQuery('#' + popupName).modal('show');
        this[popupName + 'EventCallBack'] = callBack;
    }

    showLoginPage(): void {
        this.router.navigate(['./login']);
    }

    private showPopuup(message: string) {
        this.message = message;
        jQuery('#myModal').modal('show');
        return;
    }

    redirectToAccountProfile() {
        this.router.navigate(['providerportal/accountProfile']);
    }

    updateProfile() {
        const registerDataPayload = this.constructRegisterDataPayload();
        this.editprofileService.updateData(registerDataPayload, (success: boolean, message: string) => {
            if (success) {
                let temp = true;
                if (this.inputComps.get('question1').value !== this.questionOneSelected) {
                    temp = false;
                }
                if (this.inputComps.get('question2').value !== this.questionTwoSelected) {
                    temp = false;
                }
                if ((this.inputComps.get('question1').value !== '' || this.inputComps.get('question2').value !== '') && !temp) {
                    const authToken = btoa(this.userName + ':' + this.UserCred);
                    this.editprofileService.updateSecurityQuestion(this.userName, this.inputComps.get('question1').value,
                        this.inputComps.get('question2').value, this.inputComps.get('answer1').value,
                        this.inputComps.get('answer2').value, this.UserCred, authToken, (success: boolean, message: string) => {
                            if (success) {
                                jQuery('#signInModal').modal('hide');
                                this.showAlertPopup('Your profile was updated successfully.',
                                    () => { this.router.navigate(['providerportal/accountProfile']); });
                                return;
                            } else {
                                this.showAlertPopup(message, undefined);
                                return;
                            }
                        });
                    return;
                } else {
                    jQuery('#signInModal').modal('hide');
                    this.showAlertPopup('Your profile was updated successfully.',
                        () => { this.router.navigate(['providerportal/accountProfile']); });
                }
            } else {
                this.showAlertPopup(message, undefined);
            }
        });
    }

    private constructRegisterDataPayload() {
        const inputComps = this.inputComps;
        const registerUserModel = {
            addressChangedflag: '0',
            addressLine1: inputComps.get('addressLine1').value,
            addressLine2: inputComps.get('addressLine2').value,
            city: inputComps.get('city').value,
            dataValidatedflag: 0,
            email: inputComps.get('email').value,
            extn: inputComps.get('extn').value === '' ? 'null' : inputComps.get('extn').value,
            fax: inputComps.get('fax').value === '' ? 'null' : inputComps.get('fax').value,
            firstName: inputComps.get('firstName').value,
            lastName: inputComps.get('lastName').value,
            middleName: inputComps.get('middleName').value,
            npi: inputComps.get('npi').value === '' ? 'null' : inputComps.get('npi').value,
            phone: inputComps.get('phone').value,
            securityQuestionsFlag: '1',
            state: inputComps.get('state').value,
            taxId: inputComps.get('taxId').value === '' ? 'null' : inputComps.get('taxId').value,
            userName: this.userName,
            zip: inputComps.get('zip').value,
        };
        return registerUserModel;
    }
    // Dummy to avoid build error
    uspsModelEventCallBack(address, model) {
    }

    closeModel() {
        jQuery('#alertModal').modal('hide');

    }
    
    getSecurityQuestions() {
        let responseData: any;
        this.accountProfileService.getSecurityQuestions().subscribe((response) => {
            
                responseData = response;
                if (responseData.securityQuestionsResponse) {
                    const jsonText = {
                        'question1': [],
                        'question2': [],
                    };
                    responseData.securityQuestionsResponse.forEach(data => {
                        if (data.setNumber === 'QuestionSet1') {
                            jsonText['question1'].push(data);
                        } else {
                            jsonText['question2'].push(data);
                        }
                    });
                    this.inputComps.get('question1').options = jsonText['question1'];
                    this.inputComps.get('question2').options = jsonText['question2'];
                }
           
        });
    }



    parseXml() {
        const win = window as any;
        if (win.DOMParser) {
            return function (xmlStr) {
                return (new win.DOMParser()).parseFromString(xmlStr, 'text/xml');
            };
        } else if (typeof win.ActiveXObject !== 'undefined' && new win.ActiveXObject('Microsoft.XMLDOM')) {
            return function (xmlStr) {
                const xmlDoc = new win.ActiveXObject('Microsoft.XMLDOM');
                xmlDoc.async = 'false';
                xmlDoc.loadXML(xmlStr);
                return xmlDoc;
            };
        } else {
            return function () { return null; };
        }

    }
    xmlToJson(xml) {
        const obj = {
            question1: [],
            question2: []
        };
        const soapRoot = xml.firstChild;
        const soapBody = soapRoot.firstChild;
        const questList = soapBody.firstChild;

        for (let i = 0; i < questList.childNodes.length; i++) {
            const child = questList.childNodes[i].childNodes;
            const question = {};
            for (let j = 0; j < child.length; j++) {
                const each = child[j];
                const key = each.nodeName.split(':')[1];
                question[key] = each.textContent;
            }
            if (question) {
                if (question['questionSetId'] && question['questionSetId'].match('challengeQuestion1')) {
                    obj.question1.push(question);
                } else if (question['questionSetId'] && question['questionSetId'].match('challengeQuestion2')) {
                    obj.question2.push(question);
                }
            }
        }
        return obj;
    }

    getStatesListInfo(){
        this.accountProfileService.getStatesList()
        .subscribe((data:any) =>{
            if(data && data.length){
                data.map(response => {
                    this.stateCodes.push(response.code);
                })
            }
        })
    }
    zipSelected(e) {
        // console.log(e);
        this.model.zip = e.value.split('/')[0];
        this.model.city = e.value.split('/')[1];
    }
    onCitySelect(e) {
        // console.log(e);
        this.model.zip = e.item.zipCode.split('/')[0];
        this.model.city = e.item.zipCode.split('/')[1];
    }
}
